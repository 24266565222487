import apiClient from "../../axios/axios";
import store from "../../../store/index";

export async function getLifecycleDetails() {
  try {
    const project_id = store.state.project.proj_id;
    const proj_unit_id = store.state.unitModule.unit.id;

    const endpoint = `/displayLifeCycle/${project_id}/${proj_unit_id}`;

    const res = await apiClient.get(endpoint);
    return { status: res.status, data: res.data };
  } catch (err) {
    console.log("Err on lifecycle store getter");
  }
}
